import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Position } from "@reach/popover"
import { usePersistentState } from "@modules/toolkit/hooks/usePersistentState"
import { format } from "date-fns"

const DATE_FORMAT_PATTERN = `yyyy-MM-dd`

type IncomingDataItem = {
  title: string
  message: string
  label: string
  to: string
  link: string
  linkText: string
  date: string | number | Date
}

export type DataItem = {
  title: string
  to: string
  message?: string
  linkText?: string
  isNew: boolean
}

export function useData() {
  const data = useStaticQuery(graphql`
    query DataQuery {
      allCloudAnnouncementsYaml {
        nodes {
          label
          message
          to
          linkText
          date
        }
      }
      allWpPost(sort: { fields: date, order: DESC }, limit: 2) {
        nodes {
          title
          link
          date
        }
      }
      allAllDocsSidebarYaml {
        nodes {
          label
          subItems {
            title
            subItems {
              label
              subItems {
                label
                to
                date
              }
            }
          }
        }
      }
    }
  `)

  const now = new Date()
  const formattedNow = format(now, DATE_FORMAT_PATTERN)

  const [currentVisitDate, setCurrentVisitDate] = usePersistentState<string>(
    "gatsbyjs:currentVisit",
    ""
  )
  const [lastVisitDate, setLastVisitDate] = usePersistentState<string>(
    "gatsbyjs:lastVisit",
    ""
  )

  React.useEffect(() => {
    if (currentVisitDate === ``) {
      setCurrentVisitDate(formattedNow)
    }

    if (currentVisitDate && formattedNow > currentVisitDate) {
      setCurrentVisitDate(formattedNow)
      setLastVisitDate(currentVisitDate)
    }
  }, [currentVisitDate])

  const blogPosts: DataItem[] =
    data?.allWpPost?.nodes.map((node: IncomingDataItem) => {
      const formattedDate = format(new Date(node.date), DATE_FORMAT_PATTERN)

      return {
        title: node.title,
        to: node.link,
        isNew: lastVisitDate ? formattedDate > lastVisitDate : true,
      }
    }) || []

  const guides =
    data?.allAllDocsSidebarYaml?.nodes?.[0].subItems.find(
      (item: IncomingDataItem) => item.title === `Reference Guides`
    )?.subItems || []

  const releases =
    guides.find((item: IncomingDataItem) => item.label === `Release Notes`)
      ?.subItems || []

  const releaseNotes: DataItem[] = releases
    // gets 1 recent release nodes, the 0 index node is omitted because it's an overview type node
    .filter((_: IncomingDataItem, idx: number) => idx <= 1 && idx !== 0)
    .map((item: IncomingDataItem) => {
      const formattedDate = format(new Date(item.date), DATE_FORMAT_PATTERN)

      return {
        title: item.label,
        to: item.to,
        isNew: lastVisitDate ? formattedDate > lastVisitDate : true,
      }
    })

  const announcements: DataItem[] = data?.allCloudAnnouncementsYaml?.nodes
    .filter((_: IncomingDataItem, idx: number) => idx <= 3)
    .map((item: IncomingDataItem) => {
      const formattedDate = format(new Date(item.date), DATE_FORMAT_PATTERN)

      return {
        title: item.label,
        to: item.to,
        linkText: item.linkText,
        message: item.message,
        isNew: lastVisitDate ? formattedDate > lastVisitDate : true,
      }
    })

  return {
    announcements,
    blogPosts,
    releaseNotes,
    hasNewItems:
      announcements.some(item => item.isNew) ||
      blogPosts.some(item => item.isNew) ||
      releaseNotes.some(item => item.isNew),
  }
}

export const position: Position = (targetRect, popoverRect) => {
  if (!targetRect || !popoverRect) {
    return {}
  }

  const spaceOnLeft = targetRect.left + targetRect.width
  const spaceOnRight = window.innerWidth - targetRect.right + targetRect.width

  const onLeft = spaceOnLeft >= spaceOnRight

  // forces full width on mobile viewport
  if (window.innerWidth < 450) {
    return {
      left: 20,
      right: 20,
      top: targetRect.bottom,
    }
  }

  const fitsInOnLeft = spaceOnLeft > popoverRect.width + 20
  const calculatedLeft = fitsInOnLeft
    ? targetRect.right - popoverRect.width
    : 20

  const fitsInOnRight = spaceOnRight > popoverRect.width + 20
  const calculatedRight = fitsInOnRight ? `auto` : 20

  return {
    left: onLeft ? `${calculatedLeft}px` : `${targetRect.left}px`,
    right: !onLeft ? calculatedRight : `auto`,
    top: targetRect.bottom,
  }
}
